import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiCheckbox,
  EuiDatePicker,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPageBody,
  EuiPanel,
  EuiSelect,
  EuiSpacer,
  formatDate
} from "@elastic/eui"
import {useMemo, useState} from "react";
import {Criteria} from "@elastic/eui/src/components/basic_table/basic_table";
import moment from "moment";
import {useGetVideos, VideoFull} from "../../api/apis";
import {Link, useSearchParams} from "react-router-dom";
import {parseCount} from "../../util/utils";

const columns: Array<EuiBasicTableColumn<VideoFull>> = [
  {
    field: 'thumbnailUrl',
    name: '',
    sortable: false,
    truncateText: false,
    width: "110px",
    render: (_, video: VideoFull) => (
      <img
        src={video.video.thumbnailUrl}
        style={{ width: "100px"}}
        alt=""
      />
    )
  },
  {
    field: 'title',
    name: '제목',
    sortable: true,
    width: "300px",
    truncateText: true,
    render: (_, video: VideoFull) => (
      <Link to={`/videos/${video.video.id}`}>
        {video.video.title}
      </Link>
    ),
  },
  {
    field: 'channelId',
    name: '채널',
    width: "200px",
    truncateText: true,
    render: (_, video: VideoFull) => {
      return (
        <a
          href={`https://creatordev.live/channels/${video.channel.id}`}
          target="_blank"
        >
          <EuiFlexGroup
            alignItems="center"
            justifyContent="flexStart"
            gutterSize="s"
          >
            <EuiFlexItem
              grow={false}
            >
              <img src={video?.channel.thumbnailUrl} alt="" style={{ width: "32px", height: "32px" }} />
            </EuiFlexItem>
            <EuiFlexItem>
              <b>{video?.channel?.name}</b>
            </EuiFlexItem>
          </EuiFlexGroup>
        </a>
      );
    },
  },
  {
    field: 'viewCount',
    name: "조회수",
    width: "80px",
    render: (_, data) => (`${parseCount(data.video.viewCount || 0)}`),
  },
  {
    field: 'publishedAt',
    name: "게시일",
    width: "120px",
    render: (_, video: VideoFull) =>
      formatDate(video.video.publishedAt, 'YYYY-MM-DD'),
  },
  {
    field: 'productName',
    name: "상품",
    render: (_, video: VideoFull) => {
      return (
        <EuiFlexGroup>
          <EuiFlexItem>
            <img src={video?.brand?.logoUrl} alt="" style={{ width: "32px", height: "32px" }} />
          </EuiFlexItem>
          <EuiFlexItem>
            <b>{video?.brand?.brandName}</b>
            <EuiSpacer />
            <span>{video.video.productName}</span>
          </EuiFlexItem>
        </EuiFlexGroup>
      );
    },
    truncateText: true,
    textOnly: true,
  },
];


export interface VideoSearchParams {
  channelId: string,
  videoKey: string,
  startDate: string,
  endDate: string,
  page: string,
  category: string,
  brandId?: number
  notEnteredOnly: string,
  sort: string,
}

const VideoList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const startDate: string = useMemo(() => {
    return searchParams.get("startDate") || moment().add(-8, "day").format("YYYY-MM-DD")
  }, [searchParams])

  const endDate: string = useMemo(() => {
    return searchParams.get("endDate") || moment().add(-1, "day").format("YYYY-MM-DD")
  }, [searchParams])

  const channelId: string = useMemo(() => {
    return searchParams.get("channelId") || ""
  }, [searchParams])

  const videoKey: string = useMemo(() => {
    return searchParams.get("videoKey") || ""
  }, [searchParams])

  const category: string = useMemo(() => {
    return searchParams.get("category") || ""
  }, [searchParams])

  const notEnteredOnly: string = useMemo(() => {
    return searchParams.get("notEnteredOnly") || "true"
  }, [searchParams])

  const page: number = useMemo(() => {
    const p = searchParams.get("page")
    return p ? Number(p) : 1
  }, [searchParams])

  const sort: string = useMemo(() => {
    return searchParams.get("sort") || ""
  }, [searchParams])

  const [tempChannelId, setTempChannelId] = useState<string>(channelId);
  const [tempVideoKey, setTempVideoKey] = useState<string>(videoKey);
  const [tempStartDate, setTempStartDate] = useState<moment.Moment>(moment(startDate));
  const [tempEndDate, setTempEndDate] = useState<moment.Moment>(moment(endDate));
  const [tempCategory, setTempCategory] = useState<string>(category);
  const [tempNotEnteredOnly, setTempNotEnteredOnly] = useState<string>('true');

  const params = {
    channelId,
    videoKey,
    startDate,
    endDate,
    category,
    sort,
    notEnteredOnly,
    page: `${page}`,
  }

  const { data: videos, isLoading, } = useGetVideos(params, page - 1)

  const pagination = {
    pageIndex: page - 1,
    pageSize: 20 || 0, // 페이지당 몇개
    totalItemCount: videos?.totalCount || 0, // 전체 갯수 몇개
    showPerPageOptions: false,
  };

  const onTableChange = ({ page, sort }: Criteria<VideoFull>) => {
    let nextParams = { ...params }

    if (page) {
      const { index: pageIndex, size: pageSize } = page;
      nextParams.page = `${pageIndex + 1}`
    }
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      nextParams.sort = sortField
    }

    setSearchParams(nextParams)
  };

  const applyFilter = () => {
    const nextParams = { ...params }
    nextParams.channelId = tempChannelId
    nextParams.videoKey = tempVideoKey
    nextParams.startDate = tempStartDate.format("YYYY-MM-DD")
    nextParams.endDate = tempEndDate.format("YYYY-MM-DD")
    nextParams.category = tempCategory
    nextParams.notEnteredOnly = tempNotEnteredOnly
    setSearchParams(nextParams)
  }

  const disabledFilter = !!tempChannelId || !!tempVideoKey

  return (
    <EuiPageBody
      paddingSize="xl"
      panelled
      restrictWidth
    >
      <EuiPanel hasBorder={true}>
        <EuiForm component="form">
          <EuiFlexGroup
            direction="column"
          >
            <EuiFlexItem>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow
                    label="채널ID"
                  >
                    <EuiFieldText
                      value={tempChannelId}
                      onChange={(e) => setTempChannelId(e.target.value)}
                      compressed
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label="비디오키"
                  >
                    <EuiFieldText
                      value={tempVideoKey}
                      onChange={(e) => setTempVideoKey(e.target.value)}
                      compressed
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem></EuiFlexItem>
                <EuiFlexItem></EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow
                    label="업로드일 시작"
                  >
                    <EuiDatePicker
                      selected={tempStartDate}
                      onChange={(date) => setTempStartDate(date || moment())}
                      locale="ko"
                      dateFormat="YYYY-MM-DD"
                      disabled={disabledFilter}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label="끝"
                  >
                    <EuiDatePicker
                      selected={tempEndDate}
                      onChange={(date) => setTempEndDate(date || moment())}
                      locale="ko"
                      dateFormat="YYYY-MM-DD"
                      disabled={disabledFilter}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label="PPL미입력만"
                  >
                    <EuiCheckbox
                      id={'notEnteredOnly'}
                      label="미입력만"
                      checked={tempNotEnteredOnly === 'true'}
                      onChange={(e) => setTempNotEnteredOnly(e.target.checked ? 'true' : 'false')}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label=""
                  >
                    <EuiButton
                      color="text"
                      onClick={applyFilter}
                    >
                      적용
                    </EuiButton>
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </EuiPanel>
      <EuiSpacer size="xl" />
      <EuiSpacer size="xl" />
      <EuiBasicTable
        items={videos?.items || []}
        columns={columns}
        pagination={pagination}
        // sorting={sorting}
        onChange={onTableChange}
        loading={isLoading}
        compressed
      />
    </EuiPageBody>
  )
}

export default VideoList