import {CategoryResponse} from "../../api/apis";
import {Node} from "@elastic/eui/src/components/tree_view/tree_view";
import {useMemo} from "react";
import _ from "lodash"

interface HasChildren {
  children: CategoryNode[]
}

type CategoryNode = CategoryResponse & HasChildren

const useCategoryTree = (
  categories: CategoryResponse[]|undefined,
  selected: CategoryResponse|undefined,
  onChange: (category?: CategoryResponse) => void,
) => {


  const map = (categories: CategoryResponse[]): Node[] => {
    const depth1 = {} as { [key: number]: CategoryNode }
    const depth2 = {} as { [key: number]: CategoryNode }
    const depth3 = {} as { [key: number]: CategoryNode }

    categories.forEach((item) => {
      const { depth } = item
      if(depth === 1)
        depth1[item.categoryId!!] = { ...item, children: [] }
      else if(depth === 2)
        depth2[item.categoryId!!] = { ...item, children: [] }
      else if(depth === 3)
        depth3[item.categoryId!!] = { ...item, children: [] }
    })

    // TODO 고아도 표현하기
    const orphans = []

    for (const [key, value] of Object.entries(depth3)) {
      const parent = depth2[Number(value.parentId)]
      if (!parent) {
        orphans.push(value)
      }
      parent.children.push(value)
    }

    for (const [key, value] of Object.entries(depth2)) {
      const parent = depth1[Number(value.parentId)]
      if (!parent) {
        orphans.push(value)
      }

      parent?.children?.push(value)
    }

    return Object.entries(depth1).map(([key, item]) => {
      return categoryItemToNode(item)
    })
  }

  const listToNode = (items: CategoryNode[]|undefined): Node[]|undefined => {
    if(!items || !items.length) {
      return undefined
    }

    const sorted = _.sortBy(items, "seq")
    return sorted.map((item) => categoryItemToNode(item))
  }

  const categoryItemToNode = (item: CategoryNode): Node => {
    const className = (item.status !== 'ACTIVATED' ? " deactivated" : "") +
      (selected?.categoryId == item.categoryId ? " selected" : "")
    return {
      label: item.name,
      id: `${item.categoryId}`,
      callback: () => { onChange(item); return "" },
      className: className,
      children: listToNode(item.children),
    }
  }

  return useMemo(() => {
    if(!categories)
      return null

    return [
      {
        isExpanded: true,
        label: '카테고리',
        id: "0",
        callback: () => { onChange(undefined); return "" },
        children: map(categories),
      } as Node
    ]
  }, [categories, selected])
}

export default useCategoryTree