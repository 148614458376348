import {LegacyRef, useRef, useState} from "react";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFieldText,
  EuiFilePicker,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  useGeneratedHtmlId
} from "@elastic/eui";
import axios from "axios";
import {usePostBrand} from "../api/apis";
import {categoryAssets} from "../util/consts";
import {CategoryButton} from "./CategoryButton";

export interface AddNewBrandInitialData {
  brandId?: number
  brandName?: string
  categoryCode?: string
  logoUrl?: string
}

const AddNewBrandModal = ({ initialData, onClose }: { initialData: AddNewBrandInitialData, onClose: (brandId?: number) => void }) => {
  const filePickerRef = useRef() as LegacyRef<EuiFilePicker>;
  const removeFilePickerId = useGeneratedHtmlId({ prefix: 'removeFilePicker' });
  const [ brandName, setBrandName ] = useState<string|undefined>(initialData.brandName)
  const [ categoryCode, setCategoryCode ] = useState<string|undefined>(initialData.categoryCode)
  const [ fileUrl, setFileUrl ] = useState<string|undefined>(initialData.logoUrl)
  const { mutate: postBrand } = usePostBrand()

  const onChange = async (files: FileList | null) => {
    const file = !!files && files[0]
    if (!file) {
      return
    }

    const formData = new FormData();
    formData.append('file', file);

    const uploadResponse = await axios.post(`/admin/v1/brands/logo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });

    const data = uploadResponse.data;
    const { fileUrl } = data
    setFileUrl(fileUrl)
  };

  const close = (brandId?: number) => {
    setBrandName(undefined)
    setFileUrl(undefined)
    setCategoryCode(undefined)
    onClose(brandId);
  }

  const onSubmit = () => {
    const payload = { brandId: initialData.brandId, brandName: brandName!!, logoUrl: fileUrl || "" , categoryCode, }
    postBrand(payload, {
      onSuccess: () => {
        close()
      }
    })
  }

  return (
    <EuiModal
      style={{ width: '500px'}}
      onClose={() => close(undefined)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && e.metaKey) {
          onSubmit()
        }
      }}
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          브랜드 추가
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm id="form" component="form" onSubmit={onSubmit}>
          <EuiFormRow label="브랜드명">
            <EuiFieldText
              name="brandName"
              value={brandName}
              onChange={(v) => setBrandName(v.target.value)}
              append={
              <EuiButtonEmpty
                size="xs"
                onClick={() => {
                  // 새 탭에서 구글 검색 열기
                  window.open(`https://www.google.com/search?q=${brandName}`, '_blank');
                }}
              >
                구글검색
              </EuiButtonEmpty>
            }
            />
          </EuiFormRow>
          <EuiFormRow label="브랜드로고">
            <>
              { !!fileUrl && (
                <EuiFlexGroup
                  alignItems="center"
                  gutterSize="s"
                >
                  <EuiFlexItem
                    grow={false}
                  >
                    <img
                      src={fileUrl}
                      style={{width: "40px", height: "40px", background: "black"}}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem
                    grow={false}
                  >
                    <EuiButtonIcon
                      display="base"
                      iconType="trash"
                      aria-label="Delete"
                      color="danger"
                      onClick={() => setFileUrl(undefined) }
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              )}
                {!fileUrl && (
                  <EuiFilePicker
                    ref={filePickerRef}
                    id={removeFilePickerId}
                    initialPromptText="64x64 사이즈 권장"
                    onChange={onChange}
                    display="default"
                  />
                )}
            </>
          </EuiFormRow>
          <EuiFormRow
            label="카테고리"
            helpText=""
            display="columnCompressed">
            <EuiFlexGrid
              columns={2}
              gutterSize="s"
            >
              <>
                { categoryAssets.map((category) => {
                  return (
                    <CategoryButton
                      key={category.categoryCode}
                      category={category}
                      selected={category.categoryCode === categoryCode}
                      onClick={() => setCategoryCode(category.categoryCode)}
                      style={{
                        gridColumn: category.categoryCode === '' ? 'span 2' : undefined
                      }}
                    />
                  )
                })
                }
              </>
            </EuiFlexGrid>
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={() => close()}>취소</EuiButtonEmpty>
        <EuiButton onClick={onSubmit} fill>
          생성
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  )
}

export default AddNewBrandModal;
