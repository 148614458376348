import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiDatePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPageBody,
  EuiPanel,
  EuiSpacer
} from "@elastic/eui"
import React, {useMemo, useState} from "react";
import {Criteria} from "@elastic/eui/src/components/basic_table/basic_table";
import moment from "moment";
import {AIPPLInfo, apiGetAIPPLInfos} from "../../api/apis";
import {useSearchParams} from "react-router-dom";
import {useQuery, useQueryClient} from "react-query";
import {parseCount} from "../../util/utils";
import PPLInfoWithAIModal from "../../components/PPLInfoWithAIModal";

const VideoListAI = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const startDate: string = useMemo(() => {
    return searchParams.get("startDate") || moment().add(-8, "day").format("YYYY-MM-DD")
  }, [searchParams])

  const endDate: string = useMemo(() => {
    return searchParams.get("endDate") || moment().add(-1, "day").format("YYYY-MM-DD")
  }, [searchParams])

  const page: number = useMemo(() => {
    const p = searchParams.get("page")
    return p ? Number(p) : 1
  }, [searchParams])

  const [tempStartDate, setTempStartDate] = useState<moment.Moment>(moment(startDate));
  const [tempEndDate, setTempEndDate] = useState<moment.Moment>(moment(endDate));
  const [showPPLInfoModal, setShowPPLInfoModal] = useState<AIPPLInfo>()

  const params = {
    startDate,
    endDate,
    page: `${page}`,
  }

  const { data: videos, isLoading, } = useQuery(
    [`/v1/admin/ai-ppl-info`, params, { ...params, page: page - 1}],
    () => apiGetAIPPLInfos({ ...params, page: page - 1}),
    { enabled: !!params }
  )

  const pagination = {
    pageIndex: page - 1,
    pageSize: 20 || 0, // 페이지당 몇개
    totalItemCount: videos?.totalCount || 0, // 전체 갯수 몇개
    showPerPageOptions: false,
  };

  const onTableChange = ({ page, sort }: Criteria<AIPPLInfo>) => {
    let nextParams = { ...params }

    if (page) {
      const { index: pageIndex, size: pageSize } = page;
      nextParams.page = `${pageIndex + 1}`
    }

    setSearchParams(nextParams)
  };

  const applyFilter = () => {
    const nextParams = { ...params }
    nextParams.startDate = tempStartDate.format("YYYY-MM-DD")
    nextParams.endDate = tempEndDate.format("YYYY-MM-DD")
    setSearchParams(nextParams)
  }

  const columns: Array<EuiBasicTableColumn<AIPPLInfo>> = [
    {
      field: 'videoId',
      name: 'videoId',
      sortable: false,
      truncateText: false,
      width: "100px",
      render: (videoId: number, data: AIPPLInfo) => (
        <span>
        <a href={`https://youtube.com/watch?v=${data.videoKey}`} target="_blank" onClick={e => {e.stopPropagation();}}>
          {videoId}
        </a>
      </span>
      )
    },
    {
      field: 'channelName',
      name: '채널명',
      sortable: false,
      truncateText: false,
    },

    {
      field: 'viewCount',
      name: '조회수',
      sortable: false,
      truncateText: false,
      width: "100px",
      render: (viewCount: number) => (
        <span>{parseCount(viewCount)}</span>
      )
    },
    {
      field: 'stated',
      name: '협찬 명시',
      sortable: true,
      truncateText: true,
      width: "60px",
    },
    {
      field: 'stateMessage',
      name: '협찬 근거',
      sortable: true,
      truncateText: true,
    },
    {
      field: 'brandName',
      name: '브랜드명',
      sortable: true,
      truncateText: true,
      render: (brandName: string, item: AIPPLInfo) => (
        <span onClick={() => {
          setShowPPLInfoModal(item)
        }}>{brandName}</span>
      )
    },
    {
      field: 'productName',
      name: '상품명',
      sortable: true,
      truncateText: true,
    },
    {
      field: 'otherProductNames',
      name: '다른상품 언급',
      width: "200px",
      truncateText: true,
    },
    {
      field: 'category',
      name: '카테고리',
      sortable: true,
      truncateText: true,
      width: "100px",
    },
    {
      field: 'categoryCode',
      name: '카테고리코드',
      sortable: true,
      truncateText: true,
      width: "100px",
    },
    {
      field: 'publishedAt',
      name: '업로드일',
      sortable: true,
      truncateText: true,
      render: (publishedAt: Date) => (
        <span>{moment(publishedAt).format("YYYY-MM-DD")}</span>
      )
    },
  ];

  return (
    <EuiPageBody
      paddingSize="xl"
      panelled
    >
      <EuiPanel hasBorder={true}>
        <EuiForm component="form">
          <EuiFlexGroup
            direction="column"
          >
            <EuiFlexItem>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFormRow
                    label="업로드일 시작"
                  >
                    <EuiDatePicker
                      selected={tempStartDate}
                      onChange={(date) => setTempStartDate(date || moment())}
                      locale="ko"
                      dateFormat="YYYY-MM-DD"
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label="끝"
                  >
                    <EuiDatePicker
                      selected={tempEndDate}
                      onChange={(date) => setTempEndDate(date || moment())}
                      locale="ko"
                      dateFormat="YYYY-MM-DD"
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label=""
                  >
                    <EuiButton
                      color="text"
                      onClick={applyFilter}
                    >
                      적용
                    </EuiButton>
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </EuiPanel>
      <EuiSpacer size="xl" />
      <EuiSpacer size="xl" />

      <EuiBasicTable
        items={videos?.items || []}
        columns={columns}
        pagination={pagination}
        // sorting={sorting}
        onChange={onTableChange}
        loading={isLoading}
        rowProps={(item) => ({
          onClick: () => {
            setShowPPLInfoModal(item)
          }
        })}
      />
      { showPPLInfoModal && (
        <PPLInfoWithAIModal
          initialData={{...showPPLInfoModal, adType: 'PPL', }}
          onClose={() => setShowPPLInfoModal(undefined)}
          callback={() => {
            queryClient.invalidateQueries([`/v1/admin/ai-ppl-info`, params, { ...params, page: page - 1}])
          }}
        />
      )}
    </EuiPageBody>
  )
}

export default VideoListAI