import {
  EuiBasicTable,
  EuiBasicTableColumn, EuiButton, EuiDatePicker, EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem, EuiFormRow,
  EuiPageBody, EuiSelect,
  EuiSpacer,
  formatDate
} from "@elastic/eui";
import { useGetChannels, } from "../../api/apis";
import {Link, useSearchParams} from "react-router-dom";
import {useMemo, useState} from "react";
import moment from "moment/moment";
import {ChannelEntity} from "../../api/types";
import {parseCount} from "../../util/utils";


const ChannelList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const channelKey: string = useMemo(() => {
    return searchParams.get("channelKey") || ""
  }, [searchParams])

  const channelName: string = useMemo(() => {
    return searchParams.get("channelName") || ""
  }, [searchParams])

  const category: string = useMemo(() => {
    return searchParams.get("category") || ""
  }, [searchParams])


  const page: number = useMemo(() => {
    const p = searchParams.get("page")
    return p ? Number(p) : 1
  }, [searchParams])

  const sort: string = useMemo(() => {
    return searchParams.get("sort") || ""
  }, [searchParams])


  const [tempChannelKey, setTempChannelKey] = useState<string>(channelKey);
  const [tempChannelName, setTempChannelName] = useState<string>(channelName);
  const [tempCategory, setTempCategory] = useState<string>(category);

  const params = {
    channelKey,
    channelName,
    category,
    sort,
    page: `${page}`,
  }

  const { data: channels, isLoading} = useGetChannels(params, page - 1)


  const pagination = {
    pageIndex: page - 1,
    pageSize: 20 || 0, // 페이지당 몇개
    totalItemCount: channels?.totalCount || 0, // 전체 갯수 몇개
    showPerPageOptions: false,
  };


  const applyFilter = () => {
    const nextParams = { ...params }
    nextParams.channelKey = tempChannelKey
    nextParams.channelName = tempChannelName
    nextParams.category = tempCategory
    setSearchParams(nextParams)
  }

  return (
    <EuiPageBody
      paddingSize="xl"
      panelled
      restrictWidth
    >
      <EuiFlexGroup
        direction="column"
      >
        <EuiFlexItem>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow
                label="채널키"
              >
                <EuiFieldText
                  value={tempChannelKey}
                  onChange={(e) => setTempChannelKey(e.target.value)}
                  compressed
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow
                label="채널명"
              >
                <EuiFieldText
                  value={tempChannelName}
                  onChange={(e) => setTempChannelName(e.target.value)}
                  compressed
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem></EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow
                label="액션"
              >
                <EuiButton
                  color="text"
                  onClick={applyFilter}
                >
                  적용
                </EuiButton>
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiBasicTable
        items={channels?.items || []}
        columns={columns}
        pagination={pagination}
        // sorting={sorting}
        loading={isLoading}
        onChange={() => {}}
        compressed
      />
    </EuiPageBody>
  )
}
const columns: Array<EuiBasicTableColumn<ChannelEntity>> = [
  {
    field: 'thumbnailUrl',
    name: '',
    sortable: false,
    truncateText: false,
    width: "50px",
    render: (_, channel: ChannelEntity) => (
      <img
        src={channel.thumbnailUrl}
        style={{ width: "40px"}}
        alt=""
      />
    )
  },
  {
    field: 'name',
    name: '채널명',
    sortable: true,
    width: "300px",
    truncateText: true,
    render: (_, channel: ChannelEntity) => (
      <Link to={`/channels/${channel.id}`}>
        {channel.name}
      </Link>
    ),
  },
  {
    field: 'followerCount',
    name: "구독자",
    width: "80px",
    render: (_, data) => (`${parseCount(data.followerCount)}`),
  },
  {
    field: 'category',
    name: "카테고리",
    width: "120px",
  },
  {
    field: 'status',
    name: "상태",
    truncateText: true,
  },
];

export default ChannelList