import React, {useEffect, useMemo, useState} from "react";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiComboBox,
  EuiDescriptionList,
  EuiDescriptionListDescription,
  EuiDescriptionListTitle,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiHighlight,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiRadio,
  EuiSelectable,
  EuiSpacer,
  EuiTitle
} from "@elastic/eui";
import {AIPPLInfo, apiUpdatePPLInfoWithAI, useBrands, useGetVideos} from "../api/apis";
import {categoryAssets} from "../util/consts";
import {CategoryButton} from "./CategoryButton";
import {BrandResponseWithLabel, CategoryCodeWithLabel} from "../page/VideoDetail";
import {VideoSearchParams} from "../page/VideoList";
import _ from "lodash";
import {useMutation, useQueryClient} from "react-query";

type ModalFormData = {
  brandId?: number
  adType?: string
  newBrand?: boolean
} & AIPPLInfo

const PPLInfoWithAIModal = ({initialData, onClose, callback}: { initialData: ModalFormData, onClose: VoidFunction, callback: VoidFunction }) => {
  const {data: brands} = useBrands()
  const [data, setData] = useState<ModalFormData>()
  const queryClient = useQueryClient()

  useEffect(() => {
    setData({
      ...initialData,
      adType: initialData.adType || 'PPL'
    })
  }, [initialData])

  useEffect(() => {
    if (!initialData || !brands) {
      return
    }

    // 브랜드의 name 과 initialData의 brandId가 같은 것을 찾아서 brandName으로 설정
    const brand = brands.brands.find((item) => item.brandName === initialData.brandName)
    if (brand) {
      setData({
        ...initialData,
        brandId: brand?.brandId,
      })
    }
  }, [initialData, brands]);

  const brandVideoParams = useMemo(() => {
    if (!data?.brandId) {
      return undefined
    }
    return {
      brandId: data?.brandId
    } as VideoSearchParams
  }, [data])

  const {data: brandVideos} = useGetVideos(brandVideoParams, 0)

  const brandOptions: BrandResponseWithLabel[] = useMemo(() => {
    return brands?.brands.map((item) => ({
      ...item,
      label: item.brandName,
      prepend: (
        item.logoUrl ? <img
          src={item.logoUrl || ""}
          style={{width: "28px", height: "28px", background: "black"}}
        /> : <div style={{width: "28px", height: "28px", background: "#eaeaea"}} />
      )
    })) || []
  }, [brands])

  const brandVideoOptions = useMemo(() => {
    if (!brandVideos) {
      return []
    }

    return _.chain(brandVideos?.items || [])
      .map((item) => ({
        label: item.video.productName,
        categoryCode: item.video.categoryCode,
        uniq: `${item.video.productName}-${item.video.categoryCode}`,
      } as CategoryCodeWithLabel))
      .uniqBy("uniq")
      .value()
  }, [brandVideos])

  const { mutate: updatePPLInfoWithAI } = useMutation({
    mutationFn: apiUpdatePPLInfoWithAI,
    onSuccess: () => {
      callback()
      onClose()
    }
  })

  const onSubmit = () => {
    if (!data) {
      return
    } else if (data.newBrand) {
      if (!data.brandName) {
        alert('브랜드명을 입력해주세요')
        return
      }
      if (!data.categoryCode) {
        alert('카테고리를 선택해주세요')
        return
      }
    } else {
      if (!data.brandId) {
        alert('브랜드를 선택해주세요')
        return
      }
    }

    if (!data.productName) {
      alert('상품명을 입력해주세요')
      return
    } else if (!data.adType) {
      alert('광고타입을 선택해주세요')
      return
    }

    const payload = {
      id: data.id,
      newBrand: data.newBrand!,
      brandId: data.brandId,
      brandName: data.brandName,
      categoryCode: data.categoryCode,
      productName: data.productName!,
      adType: data.adType!,
    }

    updatePPLInfoWithAI(payload)
  }

  const onPass = () => {
    if (!data) {
      return
    }

    updatePPLInfoWithAI({
      id: data.id,
      status: 'PASS',
    })
  }

  return (
    <EuiModal
      style={{width: '1200px', maxWidth: '1200px'}}
      onClose={onClose}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && e.metaKey) {
          onSubmit()
        }
      }}
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          광고제품 정보
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm id="form" component="form" onSubmit={onSubmit}>
          <EuiFlexGroup>
            <EuiFlexItem grow={1} style={{ minWidth: '370px', }}>
              <EuiTitle size="xs">
                <h4>브랜드 정보</h4>
              </EuiTitle>
              <EuiSpacer/>
              <EuiFormRow
                label={(
                  <EuiRadio
                    id={'oldBrand'}
                    label="기존 브랜드"
                    checked={data?.newBrand === false}
                    onChange={() => setData({...data!!, newBrand: false})}
                    compressed
                  />
                )}
                helpText=""
                display="columnCompressed"
              >
                <div style={{ opacity: data?.newBrand === false ? undefined : 0.6 }}>
                  <EuiComboBox
                    compressed
                    placeholder="브랜드 검색"
                    singleSelection={{asPlainText: true}}
                    options={brandOptions}
                    selectedOptions={brandOptions?.filter((item) => (item.brandId === data?.brandId))}
                    onChange={(option) => {
                      const a = option[0] as BrandResponseWithLabel
                      setData({...data!!, brandId: a?.brandId})
                    }}
                    rowHeight={50}
                  />
                </div>
              </EuiFormRow>
              <EuiFormRow
                label={(
                  <EuiRadio
                    id={'newBrand'}
                    label="브랜드 추가"
                    checked={data?.newBrand === true}
                    onChange={() => setData({...data!!, newBrand: true})}
                    compressed
                  />
                )}
                display="columnCompressed"
              >
                <div style={{ opacity: data?.newBrand === true ? undefined : 0.6 }}>
                  <EuiFieldText
                    name="brandName"
                    value={data?.brandName}
                    onChange={(v) => setData({...data!!, brandName: v.target.value})}
                    compressed
                  />
                  <EuiSpacer size={'m'}/>
                  <EuiFlexGrid
                    columns={2}
                    gutterSize="s"
                  >
                    <>
                      {categoryAssets.map((category) => {
                        return (
                          <CategoryButton
                            key={category.categoryCode}
                            category={category}
                            selected={category.categoryCode === data?.categoryCode}
                            onClick={() => setData({...data!!, categoryCode: category.categoryCode })}
                            style={{
                              gridColumn: category.categoryCode === '' ? 'span 2' : undefined
                            }}
                          />
                        )
                      })
                      }
                    </>
                  </EuiFlexGrid>
                </div>
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={1} style={{ minWidth: '300px', }}>
              <EuiFlexItem>
                <EuiTitle size="xs">
                  <h4>광고제품 정보</h4>
                </EuiTitle>
                <EuiSpacer/>
                <EuiFormRow
                  label="상품명"
                  helpText=""
                  display="columnCompressed"
                >
                  <EuiFieldText
                    name="productName"
                    value={data?.productName || ""}
                    onChange={(e) => setData({...data!!, productName: e.target.value})}
                    compressed
                  />
                </EuiFormRow>
                <EuiFormRow
                  label="광고타입"
                  helpText=""
                  display="columnCompressed"
                >
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      flexDirection: 'row',
                      gap: '10px',
                    }}
                  >
                    {
                      ["AD", "PPL", "BRANDED"].map((item) => (
                        <EuiRadio
                          id={item}
                          label={item}
                          checked={data?.adType === item}
                          onChange={() => setData({...data!!, adType: item})}
                          compressed
                        />
                      ))
                    }
                  </div>
                </EuiFormRow>
                <EuiSpacer/>
                <div>
                  <EuiFormRow
                    label="기존 입력 상품명"
                    helpText=""
                    display="columnCompressed">
                    <EuiSelectable
                      options={brandVideoOptions.map((item) => {
                        const label = `${item.label}`
                        const productName = item.label
                        return {label, productName}
                      })}
                      listProps={{bordered: true}}
                      onChange={(_, _2, option) => {
                        setData({
                          ...data!!,
                          productName: option.productName,
                        })
                      }}
                    >
                      {(list) => list}
                    </EuiSelectable>
                  </EuiFormRow>
                </div>

                <EuiSpacer/>
                <EuiTitle size="xs">
                  <h4>AI 추출</h4>
                </EuiTitle>
                <EuiSpacer size={'s'}/>
                <EuiDescriptionList compressed>
                  <EuiDescriptionListTitle>
                    협찬 명시
                  </EuiDescriptionListTitle>
                  <EuiDescriptionListDescription>
                    {data?.stateMessage || '-'}
                  </EuiDescriptionListDescription>
                  <EuiDescriptionListTitle>
                    카테고리
                  </EuiDescriptionListTitle>
                  <EuiDescriptionListDescription>
                    {data?.categoryCode} / {data?.category}
                  </EuiDescriptionListDescription>
                  <EuiDescriptionListTitle>브랜드명</EuiDescriptionListTitle>
                  <EuiDescriptionListDescription>
                    {data?.brandName}
                  </EuiDescriptionListDescription>
                  <EuiDescriptionListTitle>상품명</EuiDescriptionListTitle>
                  <EuiDescriptionListDescription>
                    {data?.productName}
                  </EuiDescriptionListDescription>
                  <EuiDescriptionListTitle>다른상품 언급</EuiDescriptionListTitle>
                  <EuiDescriptionListDescription>
                    {data?.otherProductNames || '-'}
                  </EuiDescriptionListDescription>
                </EuiDescriptionList>
              </EuiFlexItem>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiTitle size="xs">
                <h4>영상 설명</h4>
              </EuiTitle>
              <EuiSpacer size={'s'}/>
              <a href={`https://youtube.com/watch?v=${data?.videoKey}`} target="_blank" onClick={e => {
                e.stopPropagation();
              }}>
                {`https://youtube.com/watch?v=${data?.videoKey}`}
              </a>
              <EuiSpacer size={'s'}/>
              <div
                style={{maxHeight: '400px', overflowY: 'scroll', background: '#f7f7f7', padding: '1rem', borderRadius: '0.5rem' }}
              >
                <EuiHighlight
                  search={data?.stateMessage || ''}
                  style={{whiteSpace: 'pre-line',}}
                >
                  {data?.videoDescription || ''}
                </EuiHighlight>
              </div>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty color={'warning'} onClick={onPass}>패스</EuiButtonEmpty>
        <EuiButtonEmpty onClick={onClose}>취소</EuiButtonEmpty>
        <EuiButton onClick={onSubmit} fill>
          저장
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  )
}

export default PPLInfoWithAIModal;
