import * as jose from 'jose'

const LoginAuthHelper = {
  getToken: () => {
    return localStorage.getItem('authorization')
  },
  setToken: (token: string) => {
    localStorage.setItem('authorization', token)
  },
  removeToken: () => {
    localStorage.removeItem('authorization')
  },
  isLogined: () => {
    return !!localStorage.getItem('authorization')
  },
  getRoles: () => {
    const token = LoginAuthHelper.getToken()
    if (!token) {
      return undefined
    }

    const decoded = jose.decodeJwt(token)
    return decoded.roles
  },
}

export default LoginAuthHelper
