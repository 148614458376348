import {
  EuiButton,
  EuiButtonEmpty,
  EuiComboBox,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiPageBody,
  EuiPageHeader,
  EuiRadio,
  EuiSelectable,
  EuiSpacer,
  EuiTextArea,
  EuiTitle
} from "@elastic/eui";
import {useParams} from "react-router-dom";
import {
  apiPutVideoProduct,
  BrandResponse,
  CategoryResponse,
  useBrands,
  useCategories,
  useGetVideo, useGetVideos, usePutVideoProduct, VideoFull
} from "../../api/apis";
import dayjs from "dayjs";
import {useEffect, useMemo, useRef, useState} from "react";
import _ from "lodash";
import AddNewBrandModal from "../../components/AddNewBrandModal";
import {VideoEntity} from "../../api/types";
import {VideoSearchParams} from "../VideoList";
import {categoryNameWithParent} from "../../util/utils";

interface Label {
  label: string
}
export type BrandResponseWithLabel = BrandResponse & Label
export type CategoryResponseWithLabel = CategoryResponse & Label
export type CategoryCodeWithLabel = { categoryCode: string, label: string }

const VideoDetail = () => {
  const {videoId} = useParams()
  const [modalInitialData, setModalInitialData] = useState({})
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {data: videoDetail, isLoading} = useGetVideo(Number(videoId))

  const { data: brands } = useBrands()
  const { data: categories } = useCategories()

  const [data, setData] = useState<VideoEntity>()
  const { mutate: putVideoProduct, isSuccess: putVideoProductSuccess, } = usePutVideoProduct();

  const brandVideoParams = useMemo(() => {
    if(!data?.brandId) {
      return undefined
    }
    return {
      brandId: data?.brandId
    } as VideoSearchParams
  }, [data])

  const { data: brandVideos } = useGetVideos(brandVideoParams, 0)

  const brandOptions: BrandResponseWithLabel[] = useMemo(() => {
    return brands?.brands.map((item) => ({
      ...item,
      label: item.brandName,
      prepend: (
        <img
          src={item.logoUrl}
          style={{width: "32px", height: "32px", background: "black"}}
        />
      )
    })) || []
  }, [brands])

  const brandVideoOptions = useMemo(() => {
    if(!brandVideos) {
      return []
    }

    const result = _.chain(brandVideos?.items || [])
      .map((item) => ({
        label: item.video.productName,
        categoryCode: item.video.categoryCode,
        uniq: `${item.video.productName}-${item.video.categoryCode}`,
      } as CategoryCodeWithLabel))
      .uniqBy("uniq")
      .value()

    return result
  }, [brandVideos])

  const categoryMap = useMemo(() => {
    if (!categories) {
      return {}
    }

    const map = {} as { [key: number ]: CategoryResponse }

    categories?.categories.forEach((item) => {
      map[item.categoryId || 0] = item
    })

    return map
  }, [categories])

  const categoryOptions: CategoryResponseWithLabel[] = useMemo(() => {
    if (!categories) {
      return []
    }

    const options = categories?.categories.map((item) => {
      const label = categoryNameWithParent(categoryMap, item)
      return {
        ...item,
        label,
      }
    })

    return _.sortBy(options, "categoryCode")
  }, [categories, categoryMap])

  useEffect(() => {
    if (!videoDetail || !brands || !categories) {
      return
    }

    if(!data) {
      setData(videoDetail)
    }
  }, [videoDetail, brands, categories])

  const onClickSaveProduct = async () => {
    const brandId = data?.brandId
    const productName = data?.productName
    const adType = data?.adType
    const categoryCode = data?.categoryCode

    if (adType !== "AD") {
      if (!brandId) {
        alert("브랜드 선택 안됨")
        return
      }

      if (!productName) {
        alert("상품명 입력 안됨")
        return
      }

      if (!adType) {
        alert("광고타입 선택 안됨")
        return
      }

      if (!categoryCode) {
        alert("카테고리 선택 안됨")
        return
      }
    }

    const payload = {
      brandId,
      productName,
      adType,
      categoryCode,
    }

    const id = Number(videoId)
    putVideoProduct({ videoId: id, payload })
  }

  return (
    <EuiPageBody
      paddingSize="xl"
      panelled
      restrictWidth
    >
      <EuiPageHeader
        pageTitle=""
        breadcrumbs={[
          {
            text: (
              <>
                <EuiIcon size="s" type="arrowLeft"/> 뒤로
              </>
            ),
            color: 'primary',
            'aria-current': false,
            href: '#',
            onClick: (e) => e.preventDefault(),
          },
        ]}
      />
      <EuiSpacer size="xl"/>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow
            label="썸네일"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <a
              href={`https://www.youtube.com/watch?v=${data?.videoKey}`}
              target="_blank"
            >
              <img src={data?.thumbnailUrl} style={{maxWidth: "100%"}}/>
            </a>
          </EuiFormRow>
          <EuiFormRow
            label="id"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="id"
              value={data?.id}
              compressed
              isLoading={isLoading}
            />
          </EuiFormRow>
          <EuiFormRow
            label="status"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="status"
              value={data?.status}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="channelId"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="channelId"
              value={data?.channelId}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="videoKey"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="videoKey"
              value={data?.videoKey}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="title"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="title"
              value={data?.title}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="description"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiTextArea
              name="description"
              value={data?.description}
              style={{height: "180px"}}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="category"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="category"
              value={data?.category}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="viewCount"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="viewCount"
              value={data?.viewCount}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="likeCount"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="likeCount"
              value={data?.likeCount}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="commentCount"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="commentCount"
              value={data?.commentCount}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="tags"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="tags"
              value={data?.tags}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="publishedAt"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="publishedAt"
              value={dayjs(data?.publishedAt).format("YYYY-MM-DD")}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="syncedAt"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="syncedAt"
              value={dayjs(data?.syncedAt).format("YYYY-MM-DD")}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="createdAt"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="syncedAt"
              value={dayjs(data?.syncedAt).format("YYYY-MM-DD")}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="updatedAt"
            helpText=""
            display="columnCompressed"
            isDisabled
          >
            <EuiFieldText
              name="updatedAt"
              value={dayjs(data?.updatedAt).format("YYYY-MM-DD")}
              compressed
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiTitle size="xs">
            <h4>광고제품 정보</h4>
          </EuiTitle>
          <EuiSpacer/>
          <EuiFormRow
            label="브랜드"
            helpText=""
            display="columnCompressed"
          >
            <EuiComboBox
              compressed
              placeholder="브랜드 검색"
              singleSelection={{ asPlainText: true }}
              options={brandOptions}
              selectedOptions={brandOptions?.filter((item) => (item.brandId === data?.brandId))}
              onCreateOption={(searchValue) => {
                setModalInitialData({ brandName: searchValue })
                setIsModalVisible(true)
              }}
              onChange={(option) => {
                const a = option[0] as BrandResponseWithLabel
                setData({ ...data!!, brandId: a?.brandId })
              }}
              rowHeight={50}
              append={(
                <EuiButtonEmpty
                  size="xs"
                  iconType="plus"
                  style={{ height: "32px" }}
                  onClick={() => {
                    setModalInitialData({ brandName: '' })
                    setIsModalVisible(true)
                  }}
                >
                  추가
                </EuiButtonEmpty>
              )}
            />
          </EuiFormRow>
          <EuiFormRow
            label="상품명"
            helpText=""
            display="columnCompressed"
          >
            <EuiFieldText
              name="productName"
              value={data?.productName || ""}
              onChange={(e) => setData({ ...data!!, productName: e.target.value })}
              compressed
            />
          </EuiFormRow>
          <EuiFormRow
            label="제품 카테고리"
            helpText=""
            display="columnCompressed"
          >
            <EuiComboBox
              compressed
              placeholder="카테고리 검색"
              singleSelection={{ asPlainText: true }}
              options={categoryOptions}
              selectedOptions={categoryOptions?.filter((item) => (item.categoryCode === data?.categoryCode))}
              rowHeight={40}
              onChange={(option) => {
                const item = option[0] as CategoryResponseWithLabel
                setData({ ...data!!, categoryCode: item?.categoryCode })
              }}
            />
          </EuiFormRow>
          <EuiFormRow
            label="광고타입"
            helpText=""
            display="columnCompressed"
          >
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                flexDirection: 'row',
                gap: '10px',
              }}
            >
              {
                ["AD", "PPL", "BRANDED"].map((item) => (
                  <EuiRadio
                    id={item}
                    label={item}
                    checked={data?.adType === item}
                    onChange={() => setData({ ...data!!, adType: item })}
                    compressed
                  />
                ))
              }
            </div>
          </EuiFormRow>
          <EuiSpacer/>
          <div>
            <EuiFormRow
              label=" "
              helpText=""
              display="columnCompressed"
            >
              <>
                <EuiButton
                  size="s"
                  onClick={onClickSaveProduct}
                >
                  {putVideoProductSuccess && (
                    <>
                      <EuiIcon type="check" />
                      저장됨
                    </>
                  )}
                  { !putVideoProductSuccess && (
                    <>저장</>
                  )}
                </EuiButton>
              </>
            </EuiFormRow>
            <EuiFormRow
              label="기존 입력 상품명"
              helpText=""
              display="columnCompressed">
              <EuiSelectable
                options={brandVideoOptions.map((item) => {
                  const category = categories?.categories.find((category) => category.categoryCode === item.categoryCode)
                  const categoryName = category ? categoryNameWithParent(categoryMap, category) : '-'
                  const label = `${item.label} (${categoryName})`
                  const productName = item.label
                  const categoryCode = item.categoryCode
                  return { label, productName, categoryCode, }
                })}
                listProps={{ bordered: true }}
                onChange={(_, _2, option) => {
                  setData({
                    ...data!!,
                    productName: option.productName,
                    categoryCode: option.categoryCode
                  })
                }}
              >
                {(list) => list}
              </EuiSelectable>
            </EuiFormRow>
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
      { isModalVisible && (
        <AddNewBrandModal
          initialData={modalInitialData}
          onClose={(brandId?: number) => {
            if (brandId) {
              setData({ ...data!!, brandId: brandId })
            }
            setIsModalVisible(false)
          }}
        />
      )}
    </EuiPageBody>
  )
}

export default VideoDetail

