import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiPageBody,
  EuiPanel, EuiSpacer,
  EuiToken,
  EuiTreeView
} from "@elastic/eui";
import {CategoryResponse, useCategories, usePutCategory} from "../../api/apis";
import {useMemo, useState} from "react";
import useCategoryTree from "./categoryTreeMapper";
import _ from "lodash"
import { useForm } from "react-hook-form";
import "./CategoryList.scss";

const CategoryList = () => {
  const { data: categories } = useCategories()


  const [ selected, setSelected ] = useState<CategoryResponse>()
  const [ newCategory, setNewCategory] = useState<CategoryResponse>()

  const tempCategories = useMemo(() => {
    if(!categories?.categories) {
      return []
    }

    if (!newCategory)
      return categories.categories

    return [ ...categories.categories, newCategory ]
  }, [categories?.categories, newCategory])

  const { mutate: putCategory } = usePutCategory()

  const onCategoryClick = (category?: CategoryResponse) => {
    setSelected(category)
  }

  const tree = useCategoryTree(
    tempCategories,
    selected,
    onCategoryClick
  )

  const onClickNew = () => {
    if (!selected) {
      return
    }

    const maxCategoryCode = _.chain(categories?.categories || [])
      .filter((item) => item.parentId === selected.categoryId)
      .map((item) => (item.categoryCode))
      .max()
      .value()

    const maxSeq = _.chain(categories?.categories || [])
      .filter((item) => item.parentId === selected.categoryId)
      .map((item) => (item.seq))
      .max()
      .value()

    const nextSeq = (maxSeq + 1) || 0

    const nextDepth = selected.depth + 1

    const nextCategoryCode = categoryCodePlus(maxCategoryCode || selected.categoryCode, nextDepth)

    const newItem = {
      categoryId: undefined,
      status: 'ACTIVATED',
      parentId: selected.categoryId,
      depth: nextDepth,
      categoryCode: nextCategoryCode,
      name: "새로운 아이템",
      seq: nextSeq,
    } as CategoryResponse

    setNewCategory(newItem)
    setSelected(newItem)
  }

  const onClickSave = () => {
    if (!selected) {
      return
    }
    putCategory({ ...selected })
  }

  const updateSelected = (name: string, value: any) => {
    setSelected({ ...selected!!, [name]: value })
  }

  return (
    <div className="CategoryList">
      <EuiPageBody
        paddingSize="xl"
        panelled
        restrictWidth
      >
        <EuiFlexGroup
          justifyContent="flexEnd"
        >
          <EuiFlexItem
            grow={false}
          >
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup>
          <EuiFlexItem
            grow={false}
          >
            <EuiPanel
              hasBorder
            >
              <EuiFlexGroup
                justifyContent="flexEnd"
              >
                <EuiFlexItem
                  grow={false}
                >
                  <EuiButton
                    size="s"
                    color="text"
                    onClick={onClickNew}
                  >
                    추가
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiSpacer />
              <EuiTreeView
                items={tree || []}
                display="compressed"
                expandByDefault
                showExpansionArrows
                aria-label="Document Outline"
                style={{ width: "300px" }}
              />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel
              hasBorder
            >
              <EuiFlexGroup
                direction="column"
                gutterSize="s"
              >
                <EuiFlexItem>
                  <EuiFormRow
                    label="id"
                    helpText=""
                    display="columnCompressed"
                    isDisabled
                  >
                    <EuiFieldText
                      name="id"
                      value={selected?.categoryId || ""}
                      compressed
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label="categoryCode"
                    helpText=""
                    display="columnCompressed"
                    isDisabled={selected?.categoryId !== 0}
                  >
                    <EuiFieldText
                      name="categoryCode"
                      value={selected?.categoryCode}
                      onChange={(e) => updateSelected(e.target.name, e.target.value)}
                      compressed
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label="status"
                    helpText=""
                    display="columnCompressed"
                  >
                    <EuiFieldText
                      name="status"
                      value={selected?.status}
                      onChange={(e) => updateSelected(e.target.name, e.target.value)}
                      compressed
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label="categoryName"
                    helpText=""
                    display="columnCompressed"
                  >
                    <EuiFieldText
                      name="name"
                      value={selected?.name}
                      onChange={(e) => updateSelected(e.target.name, e.target.value)}
                      compressed
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label="seq"
                    helpText=""
                    display="columnCompressed"
                  >
                    <EuiFieldText
                      name="seq"
                      value={selected?.seq}
                      // onChange={(e) => setSelected({ ...selected!!, seq: Number(e.target.value)})}
                      onChange={(e) => updateSelected(e.target.name, e.target.value)}
                      compressed
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label=" "
                    helpText=""
                    display="columnCompressed"
                  >
                    <EuiButton
                      size="s"
                      onClick={onClickSave}
                    >
                      저장
                    </EuiButton>
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPageBody>
    </div>
  )
}


const categoryCodePlus = (code: string, depth: number) => {
  console.log(code, depth)
  if(depth === 0) {
    const curr = code.substring(4, 6)
    const next = Number(curr) + 1
    return code.substring(0, 4) + `${next}`.padStart(2, "0") + code.substring(7)
  } else if(depth === 1) {
    const curr = code.substring(6, 8)
    const next = Number(curr) + 1
    return code.substring(0, 6) + `${next}`.padStart(2, "0") + code.substring(8)
  } else if(depth === 2) {
    const curr = code.substring(8, 10)
    const next = Number(curr) + 1
    return code.substring(0, 8) + `${next}`.padStart(2, "0")
  }

  return "?"
}

export default CategoryList