import React, {useState} from 'react';
import './App.css';
import {BrowserRouter, Link, Navigate, Outlet, Route, Routes} from "react-router-dom";
import {
  EuiFlexGroup, EuiFlexItem,
  EuiHeader, EuiHeaderLogo, EuiHeaderSection, EuiHeaderSectionItem, EuiIcon,
  EuiPage,
  EuiPageBody,
  EuiPageSection,
  EuiPageSidebar, EuiSelectableTemplateSitewide,
  EuiSideNav,
  slugify
} from '@elastic/eui';
import VideoDetail from "./page/VideoDetail";
import SideNav from "./components/SideNav";
import VideoList from "./page/VideoList";
import ChannelList from "./page/ChannelList";
import BrandList from "./page/BrandList";
import CategoryList from "./page/CategoryList";
import ChannelDetail from "./page/ChannelDetail";
import SignIn from "./page/SignIn/SignIn";
import VideoListAI from "./page/VideoListAI";

function App() {

  return (
    <BrowserRouter>
      <EuiHeader>
        <EuiFlexGroup
          justifyContent={"center"}
          alignItems={"center"}
        >
          <EuiFlexItem grow={false}>
            <div >
              <EuiHeaderSection>
                <EuiFlexGroup>
                  <EuiFlexItem>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiHeaderSection>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHeader>
      <EuiPage
        paddingSize="none"
        restrictWidth="1480px"
        grow
      >
          <Routes>
            <Route
              element={
                <>
                <EuiPageSidebar paddingSize="l">
                  <SideNav />
                </EuiPageSidebar>
                <EuiPageSection>
                  <Outlet />
                </EuiPageSection>
                </>
              }
            >
              <Route path="/channels" element={<ChannelList />}/>
              <Route path="/channels/:channelId" element={<ChannelDetail />}/>
              <Route path="/videos" element={<VideoList />}/>
              <Route path="/videos/ai" element={<VideoListAI />}/>
              <Route path="/videos/:videoId" element={<VideoDetail />}/>
              <Route path="/categories" element={<CategoryList />}/>
              <Route path="/brands" element={<BrandList />}/>
            </Route>

            <Route
              element={
                <>
                  <EuiPageSection>
                    <Outlet />
                  </EuiPageSection>
                </>
              }
            >
              <Route path="/" element={<Navigate replace to="/signin" />}/>
              <Route path="/signin" element={<SignIn />}/>
            </Route>
          </Routes>
      </EuiPage>
    </BrowserRouter>
  );
}

export default App;
