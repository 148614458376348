import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPageBody,
  EuiPanel
} from "@elastic/eui";
import {useState} from "react";
import {useMutation} from "react-query";
import {apiSignIn} from "../../api/apis";
import {useNavigate} from "react-router-dom";

const SignIn = () => {
  const navigate = useNavigate();
  const [id, setId] = useState('')
  const [password, setPassword] = useState('')

  const { mutate: signIn } = useMutation({
    mutationFn: apiSignIn,
    onSuccess: (data, variables, context) => {
      navigate(`/videos`)
    }
  })

  const onClickSignin = () => {
    signIn({ email: id, password: password })
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      onClickSignin()
    }
  }

  return (
    <EuiPageBody
      paddingSize="xl"
      restrictWidth
    >
      <EuiFlexGroup justifyContent='center'>
        <EuiFlexItem style={{width: '400px'}} grow={false}>
          <EuiPanel type={"submit"}>
            <EuiForm component="form">
              <EuiFormRow label="ID">
                <EuiFieldText
                  name="email"
                  placeholder="email@gmail.com"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </EuiFormRow>
              <EuiFormRow label="Password">
                <EuiFieldText
                  name="password"
                  type="password"
                  placeholder="********"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </EuiFormRow>
              <EuiButton fill onClick={onClickSignin}>
                Sign-in
              </EuiButton>
            </EuiForm>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPageBody>
  )
}

export default SignIn