import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {EuiProvider} from "@elastic/eui";
import {QueryClient, QueryClientProvider} from "react-query";
import axios from "axios";
import LoginAuthHelper from './util/LoginAuthHelper';

const initAxios = () => {
  axios.defaults.baseURL = `${process.env.REACT_APP_SERVER}`

  const authorization = LoginAuthHelper.getToken()
  if (authorization) {
    axios.defaults.headers.Authorization = `${authorization}`
  }

  axios.interceptors.response.use(
    async res => {
      const authorization = res.headers.authorization

      if (authorization) {
        axios.defaults.headers.Authorization = `${authorization}`
        LoginAuthHelper.setToken(authorization)
      }

      const location = res.headers.location
      if (location) {
        // TODO global로 useNavigate 사용할 수 있으면 수정
        window.location.replace(location)
      }

      return res
    },
    error => {
      const status = error.response?.status
      if (status === 401 || status === 403) {
        axios.defaults.headers.Authorization = null
        LoginAuthHelper.removeToken()
        window.location.replace('/signin')
      }

      if (error.response?.data) {
        const message = error.response?.data?.message
        if (message) {
          window.alert(message)
        }
      }
      return Promise.reject(error)
    },
  )
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

initAxios()

root.render(
  <React.StrictMode>

    <EuiProvider colorMode="light">
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </EuiProvider>
  </React.StrictMode>
);
